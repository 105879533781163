<template>
  <div
    ref="track"
    class="relative h-12 w-full"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
  >
    <div class="absolute w-full top-1/2 -translate-y-1">
      <div class="absolute h-1 bg-gray-medium w-full z-10" />
      <div class="absolute h-1 z-20 bg-white" :style="calculateSize" />
    </div>
  </div>
</template>

<script lang="ts">
import { calculateLineHeadPosition } from '../lib/utils'

export default {
  props: {
    eventName: { type: String, default: '' },
    percentage: { type: Number, default: 0 },
    rowDirection: { type: Boolean, default: true }
  },
  emits: ['change-linehead'],
  data () {
    return {
      refId: 'track'
    }
  },
  computed: {
    calculateSize () {
      const value = this.percentage < 1 ? this.percentage * 100 : this.percentage
      return `${this.rowDirection ? 'width' : 'height'}: ${value}%`
    }
  },
  methods: {
    onMouseDown (ev: MouseEvent) {
      if (this.$refs.track instanceof HTMLElement) {
        const seekPos = calculateLineHeadPosition(ev, this.$refs.track)
        this.$emit('change-linehead', seekPos)
        document.addEventListener('mousemove', this.onMouseMove)
        document.addEventListener('mouseup', this.onMouseUp)
      }
    },
    onMouseUp (ev: MouseEvent) {
      if (this.$refs.track instanceof HTMLElement) {
        document.removeEventListener('mouseup', this.onMouseUp)
        document.removeEventListener('mousemove', this.onMouseMove)
        const seekPos = calculateLineHeadPosition(ev, this.$refs.track)
        this.$emit('change-linehead', seekPos)
      }
    },
    onMouseMove (ev: MouseEvent) {
      if (this.$refs.track instanceof HTMLElement) {
        const seekPos = calculateLineHeadPosition(ev, this.$refs.track)
        this.$emit('change-linehead', seekPos)
      }
    }
  }
}
</script>
