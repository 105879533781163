<template>
  <div class="flex flex-wrap items-left">
    <div
      v-for="file, idx in files"
      :key="file.id"
      :class="[
        activeIdx === idx ? 'basis-4/5 order-first mx-auto w-full' : 'basis-full sm:basis-1/2 sm:w-1/2',
        'sm:px-3 lg:px-6 py-4'
      ]"
    >
      <ImageGrideThumbnail :file="files[idx]" :active="activeIdx === idx" @click="clickedImage(idx)" />
    </div>
  </div>
</template>

<script lang="ts" setup>

import { IUploadableFile } from '~/composables/use-file-manager'

const activeIdx = ref(-1)

defineProps<{ files: IUploadableFile[] }>()

function clickedImage(idx: number) {
  if (activeIdx.value === idx) {
    activeIdx.value = -1
  } else {
    activeIdx.value = idx
  }
}

</script>
