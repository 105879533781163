<template>
  <SlideBase submit :forward="false" back :single-pane="true">
    <template #left>
      <div class="gap-y-5 flex-col flex-grow flex justify-around items-center">
        <div class="flex flex-col gap-y-5 flex-grow lg:w-96 justify-center mx-auto">
          <h1 class="uppercase text-center">
            Receive updates on your case and join the movement
          </h1>
          <AccordionContainer>
            <AccordionPanel title="Use Phone Number">
              <input
                id="phoneInput"
                ref="phoneInputElement"
                v-model="phoneNumberInput"
                :disabled="!!submitAnonymously"
                type="tel"
                class="
                      form-control
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      bg-transparent
                      bg-clip-padding
                      border-0
                      border-b border-solid border-gray-300
                      transition
                      ease-in-out
                      focus:ring-0
                      m-0
                      focus:border-space-orange focus:outline-none
                    "
                placeholder="Phone number"
              >
              <div>
                <InputCheckbox
                  v-model="smsOptIn"
                  :disabled="!!submitAnonymously"
                  class="text-sm inline-flex text-gray-medium"
                  :label="`I agree to be contacted via SMS from Enigma`"
                />
                <p class="font-monument text-gray-medium text-xs text-left">
                  Be alerted when your submission status is updated. Enigma encourages text opt-in to ensure we provide timely status updates and promotional messages at the cell number provided upon submission.
                </p>
              </div>
            </AccordionPanel>
            <AccordionPanel title="Use Email">
              <input
                id="emailInput"
                ref="emailInputElement"
                v-model="emailInput"
                :disabled="!!submitAnonymously"
                type="email"
                class="
                      form-control
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      bg-transparent
                      bg-clip-padding
                      border-0
                      border-b border-solid border-gray-300
                      transition
                      ease-in-out
                      focus:ring-0
                      m-0
                      focus:border-space-orange focus:outline-none
                    "
                placeholder="Email"
              >
              <div>
                <InputCheckbox
                  v-model="emailOptIn"
                  :disabled="!!submitAnonymously"
                  class="text-sm inline-flex text-gray-medium"
                  :label="`I agree to be contacted via email from Enigma`"
                />
                <p class="font-monument text-gray-medium text-xs text-left">
                  Be alerted when your submission status is updated. Unsubscribe to opt-out.
                </p>
              </div>
            </AccordionPanel>
            <AccordionPanel title="Opt out of updates">
              <InputCheckbox
                v-model="submitAnonymously"
                :disabled="!!emailInput || !!phoneNumberInput"
                label="I don’t want to provide my contact information and am opting out of any messaging that includes updates to my submission."
                class="inline-flex items-center text-sm text-gray-medium"
              />
            </AccordionPanel>
          </AccordionContainer>
        </div>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="earth-bg w-full h-full" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">
import 'intl-tel-input/build/css/intlTelInput.css'

const { emailInput, emailOptIn, phoneNumberInput, phoneNumberFull, phoneNumberValid, smsOptIn, submitAnonymously } = useNameSlide()

const phoneInputElement = ref(null)

onMounted(async () => {
  const intlTelInput = await import('intl-tel-input').then(m => m.default)
  const itl = intlTelInput(phoneInputElement.value!, {
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js'
  })

  watch(phoneNumberInput, (value) => {
    phoneNumberValid.value = itl.isValidNumber()
    phoneNumberFull.value = itl.getNumber()
  })
})

</script>
<style>
.iti__country-list {
  background-color: rgb(37 37 37);
  border: 1px solid rgb(94, 94, 94);
}
</style>
