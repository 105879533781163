<template>
  <div class="flex shadow-sm rounded-md text-sm font-light w-full font-monument" role="group">
    <button
      type="button"
      :class="[
        buttonClasses('positive'),
        'rounded-l-lg border'
      ]"
      @click="clicked('positive')"
    >
      Yes
    </button>
    <button
      type="button"
      :class="[
        buttonClasses('negative'),
        'border-t border-b'
      ]"
      @click="clicked('negative')"
    >
      No
    </button>
    <button
      type="button"
      :class="[
        buttonClasses('neutral'),
        'rounded-r-md border'
      ]"
      @click="clicked('neutral')"
    >
      Not sure
    </button>
  </div>
</template>
<script setup lang="ts">
import { MadLibMultiOption, MadLibMultiOptionValue } from '~/composables/slides/use-behavior'

const props = defineProps<{ modelValue: MadLibMultiOption }>()

function buttonClasses(state: any) {
  const classes = ['py-1 md:py-2 w-1/3 text-not-so-white ']
  if (selected.value === state) {
    classes.push('bg-space-orange bg-opacity-10 border-space-orange text-not-so-white')
  }

  return classes.join(' ')
}

const selected = computed(() => {
  return props.modelValue.selected
})

function clicked(value: MadLibMultiOptionValue) {
  if (props.modelValue.selected === value) {
    props.modelValue.selected = null
  } else {
    props.modelValue.selected = value
  }
}

</script>
