<template>
  <div class="text-center md:pt-4 flex flex-col gap-y-3 md:gap-y-6 w-80 md:w-96">
    <div v-for="(feature, i) in otherFeatures" :key="feature.title + 'mutli'" class="w-full flex flex-col">
      <span class="uppercase font-light mb-2">{{ feature.title }}</span>
      <InputMultiSelectButton v-model="otherFeatures[i]" />
    </div>
  </div>
</template>
<script setup lang="ts">

const { otherFeatures } = useBehavior()

</script>
