<template>
  <div :class="[borderColor, 'flex gap-x-5 items-center border rounded-lg py-3 px-5 justify-between']">
    <div class="flex flex-col gap-y-1">
      <div v-if="uploadable.uploadStatus === 'uploading'" class="flex gap-x-2 text-space-orange">
        Uploading
        <LoadingSpinner class="h-6 w-6" />
      </div>
      <div v-else-if="uploadable.uploadStatus === 'authenticating'">
        <span>Authenticating</span>
      </div>
      <div v-else-if="uploadable.uploadStatus === 'pending'">
        <span>Pending</span>
      </div>
      <div v-else-if="uploadable.uploadStatus === 'error'">
        <span>Error</span>
      </div>
      <div v-else-if="uploadable.uploadStatus === 'complete'" class="flex gap-x-2">
        Complete
        <IconCheck class="h-5" />
      </div>
      <ProgressBar :progress="uploadable.percentComplete" />
      <div class="text-xs font-bold text-gray-medium">
        {{ uploadable.file.name }} • {{ humanFileSize(uploadable.file.size, true) }}
      </div>
    </div>
    <div class="">
      <img
        v-if="uploadable.type === 'image'"
        :src="uploadable.url"
        :alt="uploadable.file.name"
        class="pointer-events-none h-12  group-hover:opacity-75 z-10"
        onerror="this.style.display='none'"
      >
      <video
        v-else-if="uploadable.type === 'video'"
        :src="uploadable.url"
        :alt="uploadable.file.name"
        class="pointer-events-none h-12 group-hover:opacity-75 z-10"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { IUploadableFile } from '~/composables/use-file-manager'

const borderColor = computed(() => {
  if (props.uploadable.uploadStatus === 'uploading') {
    return 'border-space-orange'
  }
  if (props.uploadable.uploadStatus === 'pending') {
    return 'border-not-so-white border-opacity-20 opacity-30'
  }

  return 'border-not-so-white border-opacity-20'
})

const props = defineProps({
  uploadable: {
    type: Object as PropType<IUploadableFile>,
    required: true
  }
})
</script>
