<template>
  <SlideBase :forward-disabled="!inputValid" back :single-pane="true">
    <template #left>
      <div class="gap-y-5 flex-col flex-grow flex justify-center text-center">
        <h1>How many witnesses (including yourself)?</h1>
        <div>
          <div class="relative text-md mx-auto w-40">
            <input
              v-model="witnessCountInput"
              type="number"
              placeholder="1"
              min="1"
              class="block w-full pr-16 focus:border-space-orange bg-transparent border-0 border-b border-gray-300 focus:ring-0"
            >
            <div class="absolute w-0 inset-y-0 right-0 flex items-center pr-16 text-sm">
              <span>{{ witnessInputLabel }}</span>
            </div>
          </div>
          <div v-if="witnessCountInput === 1 || witnessCountInput === null" class="mt-2">
            <span class="text-xs text-space-orange">
              (just me)
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="liquid-red-bg w-full h-full flex flex-col justify-center items-center">
          <div class="flex flex-col w-full p-4 sm:w-2/3 sm:p-0 gap-y-6 text-center">
            <p class="uppercase">
              26% of sightings have more than 1 witness.<br>
            </p><p>
              More eyewitnesses means more corroboration. Help drive the conversation forward and invite others who saw what you saw.
            </p>
          </div>
        </div>
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">
const { witnessCountInput, inputValid } = useWitnessCountSlide()
const { fingerprint } = useFingerprint()
const segment = useSegment()

const witnessInputLabel = computed(() => {
  if (witnessCountInput.value === 1 || witnessCountInput.value === null) {
    return 'person'
  } else {
    return 'people'
  }
})

watch(witnessCountInput, () => {
  segment.track('submission_witness_count_updated', {
    witnessCount: witnessCountInput.value
  }, {
    traits: {
      visitorId: fingerprint.value
    }
  })
})
</script>
