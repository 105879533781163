<template>
  <svg
    class="w-12 h-12"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_1958_3224)">
      <rect
        width="40"
        height="40"
        rx="20"
        fill="url(#paint0_linear_1958_3224)"
      />
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="19.5"
        stroke="#FAFAFA"
        stroke-opacity="0.4"
      />
    </g>
    <path
      d="M15.3125 25.5037V14.5857C15.3125 13.8167 16.1443 13.3355 16.811 13.7188L26.3048 19.1778C26.9735 19.5623 26.9735 20.5271 26.3048 20.9116L16.811 26.3706C16.1443 26.7539 15.3125 26.2727 15.3125 25.5037Z"
      fill="#FAFAFA"
    />
    <defs>
      <filter
        id="filter0_b_1958_3224"
        x="-20"
        y="-20"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="10"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1958_3224"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1958_3224"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1958_3224"
        x1="0"
        y1="0"
        x2="33.5391"
        y2="48.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#181818"
          stop-opacity="0.7"
        />
        <stop
          offset="1"
          stop-color="#181818"
          stop-opacity="0.4"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
