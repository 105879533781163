<template>
  <SlideBase :forward-disabled="!inputValid" forward :single-pane="true" back>
    <template #left>
      <div class="gap-y-5 flex-col flex-grow items-stretch flex justify-center items-center">
        <div class="text-center pb-2">
          <span class="block font-monument pb-2">Thank you for sharing your story.</span>
          <span class="font-monument font-sm font-light text-center">We also need to collect structured data about the
            sighting.</span>
        </div>

        <MadLib />
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="beach-bg w-full h-full" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">

const { inputValid } = useBehavior()

</script>
