<template>
  <div class="gap-y-5 flex-col flex">
    <slot />
  </div>
</template>

<script setup>
import { computed, provide, ref, useSlots } from 'vue'

const slots = useSlots()
// Array of symbols to set unique id of each panel
// eslint-disable-next-line symbol-description
const idArray = Array(slots.default().length).fill().map(() => Symbol())
// Holds the id of the currently expanded panel — default to first
const expanded = ref(idArray[0])

// Use `provide` to communicate with the child panels
provide('accordion-register', () => {
  const id = idArray.shift()

  return {
    expanded: computed(() => expanded.value === id),

    toggle () {
      expanded.value = expanded.value === id ? null : id
    },

    unregister () {
      if (expanded.value === id) {
        expanded.value = null
      }
    }
  }
})
</script>
