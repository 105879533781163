<template>
  <div class="flex flex-col gap-y-5">
    <div v-for="option, idx in options" :key="'maxlib-' + idx" class="flex flex-col gap-y-5">
      <MadLibItem v-model="options[idx]" :idx="idx" @next="activateNext(idx)" />
    </div>
  </div>
</template>
<script setup lang="ts">

const segment = useSegment()
const { fingerprint } = useFingerprint()
const { options, activeMadLibIdx, craftCount, craftShape, craftDistance, craftSize, craftMovement } = useBehavior()

function activateNext(idx: number) {
  const nextUnfinished = options.value.findIndex((val, i) => {
    return i > idx && (val.selectedIdx === null || val.selectedIdx.length === 0)
  })
  if (nextUnfinished === -1) {
    activeMadLibIdx.value = -1
    return
  }

  activeMadLibIdx.value = nextUnfinished
}

watch(craftCount, () => {
  if (craftCount.value) {
    segment.track('submission_craft_count_updated', {
      craftCount: options.value[0].options[craftCount.value[0]].label
    }, {
      traits: {
        visitorId: fingerprint.value
      }
    })
  }
})

watch(craftShape, () => {
  if (typeof craftShape.value === 'string') {
    // debounced event to allow for typing
    setTimeout(() => {
      segment.track('submission_shape_input_updated', {
        craftShape: craftShape.value
      }, {
        traits: {
          visitorId: fingerprint.value
        }
      })
    }, 3000)
  } else if (craftShape.value && typeof craftShape.value === 'object')  {
    segment.track('submission_shape_input_updated', {
      craftShape: options.value[1].options[craftShape.value[0]].label
    }, {
      traits: {
        visitorId: fingerprint.value
      }
    })
  }
})

watch(craftDistance, () => {
  if (craftDistance.value) {
    segment.track('submission_distance_input_updated', {
      craftDistance: options.value[2].options[craftDistance.value[0]].label
    }, {
      traits: {
        visitorId: fingerprint.value
      }
    })
  }
})

watch(craftSize, () => {
  if (typeof craftSize.value === 'string') {
    // debounced event to allow for typing
    setTimeout(() => {
      segment.track('submission_size_input_updated', {
        craftSize: craftSize.value
      }, {
        traits: {
          visitorId: fingerprint.value
        }
      })
    }, 3000)
  } else if (craftSize.value && typeof craftSize.value === 'object')  {
    segment.track('submission_size_input_updated', {
      craftSize: options.value[3].options[craftSize.value[0]].label
    }, {
      traits: {
        visitorId: fingerprint.value
      }
    })
  }
})

watch (craftMovement, () => {
  if (craftMovement.value) {
    segment.track('submission_movement_input_updated', {
      craftMovement: craftMovement.value.map(v => options.value[4].options[v].label)
    }, {
      traits: {
        visitorId: fingerprint.value
      }
    })
  }
})

</script>
