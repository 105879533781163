<template>
  <InputRecorder
    :active-slide="activeSlide"
    :resetting="state.resetting"
    @filechange="onFileChange"
    @reset="onReset"
    @deleteaudio="onDelete"
  />
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import { event } from 'vue-gtag'
import { watch, reactive } from 'vue'
import type { IUploadableFile } from '~/composables/use-file-manager'

const { activeSlide } = useNavigation()
const { fingerprint } = useFingerprint()
const { addFile, removeFile, fileExists } = useFileManager()
const segment = useSegment()

const state = reactive({
  resetting: 0,
  previousFile: {} as IUploadableFile
})

const onFileChange = (e: any) => {
  const file = new File([e.blob], `${uuidv4()}.wav`, { type: e.blob.type })

  state.previousFile = addFile(file)

  event('file_added', {
    event_category: 'submission',
    event_label: 'file added',
    value: 1
  })
}

const onReset = () => {
  if (Object.keys(state.previousFile).length > 0) {
    // previous file deleted on reset
    segment.track('submission_audio_recording_delete', {}, { traits: { visitorId: fingerprint.value }})
  }
  removeFile(state.previousFile)
  state.previousFile = {} as IUploadableFile
  // reset emits when new recording is started
  segment.track('submission_audio_recording_start', {}, { traits: { visitorId: fingerprint.value }})
}

const onDelete = () => {
  // delete file with no new recording started

  if (Object.keys(state.previousFile).length > 0) {
    // previous file deleted on reset
    segment.track('submission_audio_recording_delete', {}, { traits: { visitorId: fingerprint.value }})
  }
  removeFile(state.previousFile)
  state.previousFile = {} as IUploadableFile
}

// On every slide change. Make sure the file did not get removed.
// For example if the user has returned to the file upload slide
watch(activeSlide, (to) => {
  console.log(to)
  console.log(fileExists(state.previousFile?.id))
  // if to does not include previousFile anymore,
  if (!fileExists(state.previousFile?.id)) {
    state.resetting++
    state.previousFile = {} as IUploadableFile
  }
})

</script>
