<template>
  <svg class="w-12 h-12" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_1972_3521)">
    <rect width="40" height="40" rx="20" fill="url(#paint0_linear_1972_3521)"/>
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#FAFAFA" stroke-opacity="0.4"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 13.75C15.4477 13.75 15 14.1977 15 14.75V25.25C15 25.8023 15.4477 26.25 16 26.25H17.3333C17.8856 26.25 18.3333 25.8023 18.3333 25.25V14.75C18.3333 14.1977 17.8856 13.75 17.3333 13.75H16ZM22.6667 13.75C22.1144 13.75 21.6667 14.1977 21.6667 14.75V25.25C21.6667 25.8023 22.1144 26.25 22.6667 26.25H24C24.5523 26.25 25 25.8023 25 25.25V14.75C25 14.1977 24.5523 13.75 24 13.75H22.6667Z" fill="#CDCDCD"/>
    <defs>
    <filter id="filter0_b_1972_3521" x="-20" y="-20" width="80" height="80" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="10"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1972_3521"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1972_3521" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_1972_3521" x1="0" y1="0" x2="33.5391" y2="48.548" gradientUnits="userSpaceOnUse">
    <stop stop-color="#181818" stop-opacity="0.7"/>
    <stop offset="1" stop-color="#181818" stop-opacity="0.4"/>
    </linearGradient>
    </defs>
  </svg>
</template>