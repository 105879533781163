<template>
  <div class="w-full">
    <div class="w-full mb-3 bg-gray-black rounded-2xl relative pb-3 pt-6 px-4">
      <div v-if="selected?.url" class="w-full mb-8">
        <audio-player v-if="selected.url" :record="selected" />
      </div>
      <div v-if="!selected.url" class="font-monument select-none text-right w-full text-gray-dark top-3 absolute right-4">
        Max {{ convertTimeMMSS(time * 60) }}
      </div>

      <div v-if="!selected.url" class="w-full">
        <div class="flex justify-center items-center">
          <!-- {{  !!selected?.url }} -->
          <svg
            v-if="!isRecording && !selected.url"
            class="mx-auto cursor-pointer"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="startRecording"
          >
            <circle cx="28" cy="28" r="28" fill="#FAFAFA" />
            <path d="M33.0325 19.3782C33.0325 16.546 30.7806 14.25 28.0029 14.25C25.2251 14.25 22.9733 16.546 22.9733 19.3782V27.5833C22.9733 30.4156 25.2251 32.7115 28.0029 32.7115C30.7806 32.7115 33.0325 30.4156 33.0325 27.5833V19.3782Z" stroke="#FF5935" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.0028 37.8141V41.9167" stroke="#FF5935" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M37.0061 29.609C36.7451 31.8566 35.6844 33.9286 34.025 35.432C32.3656 36.9354 30.2229 37.7658 28.0031 37.7658C25.7833 37.7658 23.6406 36.9354 21.9812 35.432C20.3218 33.9286 19.2611 31.8566 19.0001 29.609" stroke="#FF5935" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <svg
            v-else-if="isRecording && !selected.url"
            width="57"
            height="56"
            viewBox="0 0 57 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="cursor-pointer"
            @click="finishRecording"
          >
            <circle cx="28.5" cy="28" r="26.5" stroke="#FAFAFA" stroke-width="3" />
            <rect
              x="19.5"
              y="19"
              width="18"
              height="18"
              rx="4"
              fill="#FF5935"
            />
          </svg>
        </div>
      </div>

      <div class="uppercase font-sans absolute bottom-3 right-4 select-none">
        <div v-if="!isRecording && !isPause && selected.url" class="text-not-so-white">
          {{ selected.duration }}
        </div>
        <div v-else :class="{'text-space-orange' : isRecording && !isPause, 'text-not-so-white': !isRecording && isPause }">
          {{ recordedTime }}
        </div>
      </div>
      <div class="uppercase font-sans absolute bottom-3 left-4">
        <span v-if="isRecording && !isPause" class="text-space-orange cursor-pointer" @click="toggleRecorder">
          Pause
        </span>
        <span v-else-if="isRecording && isPause" class="text-space-orange cursor-pointer" @click="toggleRecorder">
          Resume
        </span>
        <div v-else-if="!isRecording && !isPause && selected.url" class="flex gap-2">
          <span v-show="!deletingAudio" class="text-not-so-white cursor-pointer" @click="retry">
            Retry
          </span>
          <span v-show="!canceling" class="text-gray-medium cursor-pointer" @click="deleteAudio">
            Delete
          </span>
        </div>
      </div>
    </div>

    <div v-if="canceling || deletingAudio" class="w-full pt-4 pb-6 px-4 bg-gray-black rounded-sm">
      <p class="text-white font-monument text-left mb-4">
        <span class="max-w-[35ch] inline-block">
          Do you want to remove the current recording<span v-if="canceling"> and start a new one</span>?
        </span>
      </p>
      <div v-if="canceling" class="flex gap-8">
        <p class="text-space-orange uppercase cursor-pointer" @click="confirm">
          Start new
        </p>
        <p class="text-white uppercase cursor-pointer" @click="cancel">
          Keep current
        </p>
      </div>
      <div v-if="deletingAudio" class="flex gap-8">
        <p class="text-space-orange uppercase cursor-pointer" @click="confirmDelete">
          Remove recording
        </p>
        <p class="text-white uppercase cursor-pointer" @click="cancelDelete">
          Keep current
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { stubFalse } from 'cypress/types/lodash'
import AudioPlayer from './AudioPlayer.vue'
import Recorder from './lib'
import { convertTimeMMSS } from './lib/utils'

export default {
  components: {
    AudioPlayer
    // Downloader,
    // IconButton,
    // Uploader
  },
  // mixins: [UploaderPropsMixin],
  props: {
    resetting: { type: Number, default: 0 },
    activeSlide: { type: Number, default: 0 },
    format: { type: String, default: 'wav' },
    attempts: { type: Number, default: 1 },
    time: { type: Number, default: 5 },

    bitRate: { type: Number, default: 128 },
    sampleRate: { type: Number, default: 44100 },

    showDownloadButton: { type: Boolean, default: true },
    showUploadButton: { type: Boolean, default: true }
  },
  emits: ['filechange', 'reset', 'deleteaudio'],
  data () {
    return {
      isUploading: false,
      recorder: this._initRecorder(),
      recordList: [],
      selected: {} as any,
      uploadStatus: null,
      canceling: false,
      deletingAudio: false,
    }
  },
  computed: {
    attemptsLeft () {
      return this.attempts - this.recordList.length
    },
    iconButtonType () {
      return this.isRecording ? 'stop' : 'mic'
      // return this.isRecording && this.isPause ? 'mic' : this.isRecording ? 'pause' : 'mic'
    },
    isPause () {
      return this.recorder.isPause
    },
    isRecording () {
      return this.recorder.isRecording
    },
    recordedTime () {
      if (this.time && this.recorder.duration >= this.time * 60) {
        this.finishRecording()
      }
      return convertTimeMMSS(this.recorder.duration)
    },
    volume () {
      return parseFloat(this.recorder.volume)
    }
  },
  watch: {
    activeSlide (to, from) {
      if (to !== from) {
        if (this.isRecording) {
          this.stopRecorder()
          this.removeRecord(0)
        }
      }
    },
    resetting (to, from) {
      if (to !== from) {
        // Clear in the UI
        this.removeRecord(0)
      }
    },
  },
  beforeUnmount () {
    this.stopRecorder()
  },
  methods: {
    convertTimeMMSS,
    deleteAudio () {
      this.deletingAudio = true
    },
    cancelDelete () {
      this.deletingAudio = false
    },
    confirmDelete () {
      this.deleteRecord(0)
      this.deletingAudio = false
    },
    retry () {
      this.canceling = true
    },
    cancel () {
      this.canceling = false
    },
    confirm () {
      this.canceling = false
      this.startRecording()
    },
    startRecording () {
      this.$emit('reset', this.selected)
      this.removeRecord(0)
      //
      this.recorder.start()
    },
    finishRecording () {
      this.recorder.pause()
      // Immediately stop the recorder
      this.stopRecorder()
      // And choose the last made one for commit, if over 5 secs
      const latestRecord = this.recorder.records[0]
      if (latestRecord.durationSeconds > 5) {
        this.chooseRecord(latestRecord)

        this.$emit('filechange', this.selected)
      } else {
        log.trace('Removing recording under five seconds')
        this.removeRecord(0)
      }
    },
    toggleRecorder () {
      if (this.attempts && this.recorder.records.length >= this.attempts) {
        return
      }

      if (!this.isRecording || (this.isRecording && this.isPause)) {
        this.recorder.start()
      } else {
        this.recorder.pause()
        // Immediately stop the recorder
      }
    },
    stopRecorder () {
      if (!this.isRecording) {
        return
      }

      this.recorder.stop()
      this.recordList = this.recorder.recordList()
    },
    deleteRecord (idx: number) {
      if (this.recordList.length === 0) { return }

      this.recordList.splice(idx, 1)
      this.selected.url = null

      this.$emit('deleteaudio')
    },
    removeRecord (idx: number) {
      if (this.recordList.length === 0) { return }

      this.recordList.splice(idx, 1)
      this.selected.url = null

      this.$emit('reset')
    },
    chooseRecord (record: any) {
      if (this.selected === record) {
        return
      }
      this.selected = record
      // this.selectRecord && this.selectRecord(record)
    },
    _initRecorder () {
      return new Recorder({
        // beforeRecording: this.beforeRecording,
        // afterRecording: this.afterRecording,
        // pauseRecording: this.pauseRecording,
        // micFailed: this.micFailed,
        bitRate: this.bitRate,
        sampleRate: this.sampleRate,
        format: this.format
      })
    }
  }
}
</script>
