<template>
  <SlideBase forward :forward-disabled="!featureInputValid">
    <template #left>
      <div class="flex flex-col md:pt-2 items-center justify-center gap-y-4 md:gap-y-8 flex-grow w-full">
        <h1>Did the {{ uapLabel }}:</h1>
        <MadLibFeatures />
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2" :feature-pane="true">
        <FeatureImages />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">

const { featureInputValid, singular } = useBehavior()

const uapLabel = computed(() => {
  if (singular.value === false) {
    return 'UAPs'
  } else {
    return 'UAP'
  }
})

</script>
