<template>
  <div class="flex flex-col gap-y-5 border border-not-so-white border-opacity-20 rounded-lg py-3 px-5" :class="{ expanded }">
    <div class="flex flex-row items-center justify-between uppercase text-gray-medium font-sans text-sm" @click="toggle">
      {{ props.title }}
      <IconChevronUp v-show="expanded" class="h-4" />
      <IconChevronDown v-show="!expanded" class="h-4" />
    </div>
    <!-- using instead of v-if to maintain intl-tel-input script on mount -->
    <div :class="expanded ? 'block' : 'hidden'">
      <div class="flex flex-col gap-y-5">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, onUnmounted } from 'vue'

const props = defineProps({
  title: {
    required: true,
    type: String
  }
})

const register = inject('accordion-register')

const { expanded, toggle, unregister } = register()

onUnmounted(unregister)
</script>
